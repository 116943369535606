.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
  }

  .footer {
    flex-grow: 0;
  }
}

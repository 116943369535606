/* CERN Toolbar CSS - 17/01/13 */

#cern-toolbar {
  background-color: #222;
  background-color: rgba(30, 30, 30, 0.95);
  font-family: "PT Sans", "Trebuchet MS", Tahoma, sans-serif;
  color: #999;
  font-size: 14px;
  line-height: 1;
  padding: 0 5%;
  overflow: hidden;
  -moz-box-shadow: inset 0 -1px 0 rgba(100, 100, 100, 0.2);
  -webkit-box-shadow: inset 0 -1px 0 rgba(100, 100, 100, 0.2);
  box-shadow: inset 0 -1px 0 rgba(100, 100, 100, 0.2);
  height: 40px;
  :focus {
    border: 2px solid black !important;
  }
}

#cern-toolbar a {
  color: #ccc;
  text-decoration: none;
  padding: 6px 8px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: inline-block;
}

#cern-toolbar a:hover {
  background-color: #000;
  color: #fff;
}

#cern-toolbar .active a {
  background-color: #f6f6f6;
  color: #000;
  padding-bottom: 20px;
}

#cern-toolbar .active .cern-account {
  background-color: #fff;
}

#cern-toolbar h1 {
  float: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  position: relative;
  left: -8px;
  padding: 6px 0;
  letter-spacing: 0.08em;
  margin: 0;
}

#cern-toolbar h1 a {
  color: #fff;
}

#cern-toolbar h1 span {
  color: #999;
  letter-spacing: normal;
  padding: 0;
}

#cern-toolbar h1 a:hover span {
  color: #fff;
}

#cern-toolbar ul {
  float: right;
  list-style: none;
  position: relative;
  right: -8px;
  margin: 0;
}

#cern-toolbar li {
  float: left;
  display: block;
  margin-left: 1em;
  padding: 6px 0;
}

#cern-toolbar:focus {
  border: 2px solid #09f !important;
}

#cern-toolbar .cern-multifactor {
  padding-left: 32px;
  /*background-image: url("../img/toolbarsprite.png");*/
  background-repeat: no-repeat;
  background-position: 0 -120px;
}

#cern-toolbar .cern-signout {
  margin-left: 1em;
}

/* Animations */

#cern-toolbar a,
#cern-toolbar a span {
  -moz-transition: background-color 0.1s ease-out;
  -webkit-transition: background-color 0.1s ease-out;
  -ms-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
}

@media only screen and (max-width: 750px) {
  #cern-toolbar h1 span {
    display: none;
  }

  #cern-toolbar ul {
    border-right: 1px solid #000;
    -moz-box-shadow: 1px 0 0 #444;
    -webkit-box-shadow: 1px 0 0 #444;
    box-shadow: 1px 0 0 #444;
  }

  #cern-toolbar li {
    padding: 0;
    margin: 0;
    border-left: 1px solid #000;
  }

  #cern-toolbar li a {
    /*background-image: url("../img/toolbarsprite.png");*/
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    text-indent: -5000px;
    overflow: hidden;
    border-left: 1px solid #444;
  }

  #cern-toolbar .cern-account {
    background-position: 9px 0;
  }

  #cern-toolbar .cern-directory {
    background-position: 9px -40px;
  }

  #cern-toolbar .cern-signout {
    background-position: 9px -80px;
    margin-left: 0;
  }

  #cern-toolbar .active .cern-account {
    background-position: -31px 0;
  }

  #cern-toolbar .active .cern-directory {
    background-position: -31px -40px;
  }

  #cern-toolbar .cern-accountlinks span {
    display: none;
  }

  #cern-toolbar .cern-multifactor {
    background-image: none;
    padding: 0;
  }
}

.table-header {
  background: rgba(0, 0, 0, 0.03) !important;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.95) !important;
}

.table-footer {
  display: flex;
  justify-content: center;
}

.tabs {
  :focus {
    border: 2px solid rgb(209, 209, 209) !important;
  }
}

.tabs-pane {
  :focus {
    border: 2px solid black !important;
  }
}

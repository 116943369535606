.userIcon {
  font-size: 26px !important;
}

.footer {
  justify-content: center;
}

.blocked {
  color: red;
}

.active {
  color: #21ba45;
}

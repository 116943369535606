body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: visible !important;

  min-width: 320px;
  z-index: 1;
  position: relative;
  :focus {
    border: 2px solid black !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Accessible link colour*/
a {
  color: #0066cc;
}

#main-loader-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.active-policy {
  color: #1890ff;
}

.icon-circle {
  font-size: 10px !important;
}

.password-policy-margin-top {
  margin-top: 40px !important;
}

.password-policy-margin-left {
  margin-left: 20px !important;
}

.password-oc5-margin {
  margin-top: 10px !important;
}

.truncatedPropertyText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 14rem;
  display: inline-block;
  vertical-align: middle;
}

.truncatedTableText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 18rem;
  display: inline-block;
  vertical-align: middle;
}

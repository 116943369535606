.account-association-modal {
  overflow-y: auto;
  height: 70%;
}

.content {
  height: 90%;
}

.nav-link {
  color: #06c;
}
